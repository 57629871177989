<template>
	<div class="id-status-bar">
		<div class="is-number">TICKET #{{ ticket.id }}</div>
		<div class="is-status" v-if="isAdmin">
			<v-select
				v-model="ticket.status"
				outlined
				dense
				hide-details="auto"
				:items="statuses"
				item-text="label"
				item-value="value"
				label="Status" />
		</div>
		<div class="is-status" v-else>
			Status:
			<strong>{{ currentStatus }}</strong>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			statuses: [
				{
					label: this.$t("pages.contact.status.waitingFluke"),
					value: "waiting_admin",
				},
				{
					label: this.$t("pages.contact.status.waitingSeller"),
					value: "waiting_seller",
				},
				{
					label: this.$t("pages.contact.status.closed"),
					value: "closed",
				},
			],
		};
	},
	computed: {
		currentStatus() {
			var status = this.ticket.status;
			switch (status) {
				case "waiting_seller":
					status = "Aguardando usuário";
					break;
				case "waiting_admin":
					status = "Aguardando fluke";
					break;
				case "closed":
					status = "Fechado/Resolvido";
					break;
			}
			return status;
		},
		isAdmin() {
			var isAdmin = true;

			if (this.$store.state.auth.user.distributorID) {
				return false;
			}

			return isAdmin;
		},
		ticket() {
			return this.$store.state.contact.view.ticket;
		},
	},
	watch: {
		"ticket.status": function (value) {
			this.$store.dispatch("contact/setStatus");
		},
	},
};
</script>
