<template>
	<div>
		<f-default-header
			:title="$t('pages.contact.title')"
			:subtitle="$t('pages.contact.view.subtitle')" />

		<f-ticket-bar />

		<f-view />

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="send">
				{{ $t("labels.btnSend") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FTicketBar from "../../components/contact/IDStatusBar.vue";
import FView from "../../components/contact/View.vue";

export default {
	components: {
		FTicketBar,
		FView,
	},
	data: () => ({
		id: null,
	}),
	created() {
		this.id = this.$route.params.id;
		this.getData();
	},
	methods: {
		async getData() {
			this.$store.dispatch("contact/get", this.id);
		},
		back() {
			this.$router.push({
				name: "contact-list",
			});
		},
		async send() {
			await this.$store.dispatch("contact/respond");
			this.$store.dispatch("contact/get", this.id);
		},
	},
};
</script>
